<template>
  <div class="AddCustomer">
    <el-dialog
      :center="true"
      @close="tableVisible = false"
      :visible="tableVisible"
      v-if="tableVisible"
      :title="title"
      width="50%"
    >
      <div>
        <el-form
          :model="formData"
          :rules="rules"
          ref="ruleForm"
          label-width="80px"
        >
          <el-form-item label="选择销售" prop="sale_id">
            <el-select
              placeholder="选择选择销售"
              v-model="formData.sale_id"
              clearable
              filterable
            >
              <el-option
                v-for="item of sale_list"
                :key="item.id"
                :label="item.fullname"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <el-button @click="tableVisible = false">返回</el-button>
        <el-button @click="determine" type="primary" :loading="bntloading"
          >确定</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>
    
    <script>
export default {
  name: "AddCustomer",
  props: {},
  data() {
    return {
      tableVisible: false,
      bntloading: false,
      title: "",
      formData: {
        sale_id: "",
        member_ids: "", //	T文本	是	549集配中心id
      },
      sale_list: "",
      rules: {
        sale_id: [
          { required: true, message: "选择销售", trigger: "change" },
        ],
      },
    };
  },
  computed: {},
  created() {},

  methods: {
    hqinfo(item, sale_list) {
      this.title = "绑定销售";
      this.tableVisible = true;
      this.formData.sale_id=''
      this.sale_list = sale_list;
    let arr=JSON.parse(JSON.stringify(item))
   this.formData.member_ids=arr.map(el=>el.id)
    },

    determine() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.bntloading = true;
          this.tool.debounce(() => {
            this.$api.SalesP.salesbindmember(this.formData)
              .then((res) => {
                this.$message({
                  type: "success",
                  message: "添加成功",
                });
                this.bntloading = false;
                this.tableVisible = false;
                this.$emit("getList", this.formData);
              })
              .catch((err) => {
                this.bntloading = false;
              });
          });
        }
      });
    },
  },
};
</script>
    
    <style lang="scss" scoped>
.AddCustomer {
  .pagination-position {
    margin-top: 20px;
  }
}
</style>
    