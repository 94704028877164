<template>
  <div class="orderlist bj freight-container">
    <div class="title">
      <div class="d-flex a-center">
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>
        <el-button @click="binding" type="primary">绑定销售</el-button>
      </div>
      <div class="seach">
        <div class="inputs">
          <el-select
            v-model="from1.sale_id"
            clearable
            filterable
            placeholder="所属销售"
          >
            <el-option
              :label="item.fullname"
              :value="item.id"
              :key="item.id"
              v-for="item in salelist"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.mobile"
            placeholder="请输入联系电话"
            clearable
          ></el-input>
        </div>
        <el-button @click="search" type="primary" icon="el-icon-search"
          >查询</el-button
        >
      </div>
    </div>

    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        @selection-change="handleSelectionChange"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        ref="multipleTable"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column type="selection" width="80" fixed align="center">
        </el-table-column>
        <el-table-column prop="order_no" align="center" label="客户名称">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.fullname) }}
          </template>
        </el-table-column>
        <el-table-column prop="create_time" align="center" label="联系电话">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.mobile) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="店铺名称">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.shopname) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="从事行业">
          <template slot-scope="scope">
            {{ $empty.empty(industry[scope.row.industry]) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="注册时间">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="注册天数">
          <template slot-scope="scope">
            {{ scope.row.days }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="日均下单数">
          <template slot-scope="scope">
            {{ scope.row.average_order_number }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="下单总数">
          <template slot-scope="scope">
            {{ scope.row.order_number }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="日均下单重量">
          <template slot-scope="scope">
            {{ scope.row.average_order_gross_weight }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="下单总重">
          <template slot-scope="scope">
            {{ scope.row.order_gross_weight }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="复购率(%)">
          <template slot-scope="scope">
            {{ scope.row.rep_rate }}
          </template>
        </el-table-column>
        <el-table-column prop="sale_fullname" align="center" label="所属销售">
          <template slot-scope="scope">
            {{ scope.row.sale_fullname }}
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from1.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <bindingsales @getList="getList" ref="bindingsales"></bindingsales>
  </div>
</template>
<script>
  import bindingsales from "./modules/bindingsales.vue";
export default {
  name: "member",
  components: {bindingsales},
  data() {
    return {
      //列表新建页面初始参数
      loading: true,
      liquidate_amount_total: "",
      industry: {
        1: "中大型商超",
        2: "水果生鲜店",
        3: "企事业单位采购",
        4: "二批商户",
        5: "团购/团购领域",
        99: "—",
      },
      from: {
        sale_id:'',
        mobile: "",
        page: 1,
        pageSize: 10,
      },
      from1: {
        sale_id:'',
        mobile: "",
        page: 1,
        pageSize: 10,
      },
      tableData: [],
      currentPage: 1,
      total: 0,
      storelist: [],
      salelist: [],
      multipleSelection: [],
    };
  },
  created() {
    if (window.location.href.indexOf("?") > -1) {
      /** 历史丢参问题，修复 start */
      const url = this.tool.getUrl(window.location.href);
      // 无效的值
      const invalid = ["", null, undefined, "null", "undefined"];
      url.page = (invalid.includes(url.page) && 1) || url.page;
      url.pageSize = (invalid.includes(url.pageSize) && 10) || url.pageSize;
      /** 历史丢参问题，修复 end */
      this.from = url;
      this.from1 = url;
      this.currentPage = Number(this.from1.page);
      this.from1.pageSize = Number(this.from1.pageSize);
      console.log(this.from1);
    }
    this.hqlist();
    this.getsaleselectlist();
  },
  methods: {
    getList(e) {
 
        this.hqlist();
   
    },
    binding() {
      if (this.multipleSelection.length == 0) {
        this.$message.error("请选择会员");
      } else {
        this.$refs.bindingsales.hqinfo(this.multipleSelection,this.salelist);
      }
    },
    handleSelectionChange(val) {
      console.log(val, "选中的数据");
      //   this.$emit("tablevalue", val);
      this.multipleSelection = val;
    },
    getsaleselectlist() {
      this.$api.SalesP.saleselectlist().then((res) => {
        this.salelist = res.data;
      });
    },
    search() {
      this.from.page = 1;
      this.from1.page = 1;
      this.currentPage = 1;
      this.from = this.tool.DeepCopy({}, this.from1);
      this.hqlist();
      this.tool.getResult(this.from, window.location.href);
    },
    Refresh() {
      this.from = {
        sale_id:'',
        mobile: "",
        page: 1,
        pageSize: 10,
      };
      this.from1 = {
        sale_id:'',
        mobile: "",
        page: 1,
        pageSize: 10,
      };
      this.currentPage = 1;
      this.tool.getResult(this.from1, window.location.href);
      this.hqlist();
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.tool.getResult(this.from1, window.location.href);
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.tool.getResult(this.from1, window.location.href);
      this.hqlist();
    },
    hqlist() {
      this.loading = true;
      this.$api.tdc.memberList(this.from).then((res) => {
        console.log(res, "获取数据");
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.liquidate_amount_total = res.data.liquidate_amount_total;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss">
.freight-container.orderlist {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .bjsearch {
    background: #333333;
    border-color: #333333;
    margin-right: 5px;
  }

  .bjsearch:active {
    background: #333333;
    border-color: #333333;
  }

  .checked-box {
    padding: 13px 15px;
  }

  .title {
    background: #ffffff;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    .title-style {
      color: #999999;
      span {
        color: #13ae67;
        font-size: 20px;
      }
    }
    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      .inputs {
        // width: 207px;
        margin-right: 8px;
      }

      .multiple-select {
        width: 250px;
      }
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }

  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;

    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }

    .imgs {
      display: flex;

      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;

        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }

        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }

    .disply {
      display: flex;
      margin-bottom: 22px;
    }

    .mr {
      margin-right: 31px;
    }

    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }

      .el-input {
        width: 302px;
      }
    }
  }

  .colors {
    color: #fa884c;
  }

  .times {
    margin-right: 8px;
  }

  // .el-picker-panel {
  //   left: 1245px !important;
  // }
}
</style>
